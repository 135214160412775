/*!
 * Font Awesome Pro 5.1.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 400;
  src: url("../webfonts/fa-regular-400.woff2") format("woff2");
}

.far {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}