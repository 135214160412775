.windows8 {
position: relative;
width: 100%;
height:100%;
}

.windows8 .wBall {
position: absolute;
left:5px;
top:5px;
right:5px;
bottom:5px;
opacity:0;
-moz-transform: rotate(225deg);
-moz-animation: orbit 5.5s infinite;
-webkit-transform: rotate(225deg);
-webkit-animation: orbit 5.5s infinite;
-ms-transform: rotate(225deg);
-ms-animation: orbit 5.5s infinite;
-o-transform: rotate(225deg);
-o-animation: orbit 5.5s infinite;
transform: rotate(225deg);
animation: orbit 5.5s infinite;
}

.windows8 .wInnerBall{
position: absolute;
width: 8px;
height: 8px;
background: #FFFFFF;
left:0px;
top:0px;
-moz-border-radius: 8px;
-webkit-border-radius: 8px;
-ms-border-radius: 8px;
-o-border-radius: 8px;
border-radius: 8px;
}

.windows8 .wInnerBall.small{
  width: 4px;
  height: 4px;
}

.windows8 #wBall_1 {
-moz-animation-delay: 1.2s;
-webkit-animation-delay: 1.2s;
-ms-animation-delay: 1.2s;
-o-animation-delay: 1.2s;
animation-delay: 1.2s;
}

.windows8 #wBall_2 {
-moz-animation-delay: 0.24s;
-webkit-animation-delay: 0.24s;
-ms-animation-delay: 0.24s;
-o-animation-delay: 0.24s;
animation-delay: 0.24s;
}

.windows8 #wBall_3 {
-moz-animation-delay: 0.48s;
-webkit-animation-delay: 0.48s;
-ms-animation-delay: 0.48s;
-o-animation-delay: 0.48s;
animation-delay: 0.48s;
}

.windows8 #wBall_4 {
-moz-animation-delay: 0.72s;
-webkit-animation-delay: 0.72s;
-ms-animation-delay: 0.72s;
-o-animation-delay: 0.72s;
animation-delay: 0.72s;
}

.windows8 #wBall_5 {
-moz-animation-delay: 0.96s;
-webkit-animation-delay: 0.96s;
-ms-animation-delay: 0.96s;
-o-animation-delay: 0.96s;
animation-delay: 0.96s;
}

@-moz-keyframes orbit {
0% {
opacity: 1;
z-index:99;
-moz-transform: rotate(180deg);
-moz-animation-timing-function: ease-out;
}

7% {
opacity: 1;
-moz-transform: rotate(300deg);
-moz-animation-timing-function: linear;
-moz-origin:0%;
}

30% {
opacity: 1;
-moz-transform:rotate(410deg);
-moz-animation-timing-function: ease-in-out;
-moz-origin:7%;
}

39% {
opacity: 1;
-moz-transform: rotate(645deg);
-moz-animation-timing-function: linear;
-moz-origin:30%;
}

70% {
opacity: 1;
-moz-transform: rotate(770deg);
-moz-animation-timing-function: ease-out;
-moz-origin:39%;
}

75% {
opacity: 1;
-moz-transform: rotate(900deg);
-moz-animation-timing-function: ease-out;
-moz-origin:70%;
}

76% {
opacity: 0;
-moz-transform:rotate(900deg);
}

100% {
opacity: 0;
-moz-transform: rotate(900deg);
}

}

@-webkit-keyframes orbit {
0% {
opacity: 1;
z-index:99;
-webkit-transform: rotate(180deg);
-webkit-animation-timing-function: ease-out;
}

7% {
opacity: 1;
-webkit-transform: rotate(300deg);
-webkit-animation-timing-function: linear;
-webkit-origin:0%;
}

30% {
opacity: 1;
-webkit-transform:rotate(410deg);
-webkit-animation-timing-function: ease-in-out;
-webkit-origin:7%;
}

39% {
opacity: 1;
-webkit-transform: rotate(645deg);
-webkit-animation-timing-function: linear;
-webkit-origin:30%;
}

70% {
opacity: 1;
-webkit-transform: rotate(770deg);
-webkit-animation-timing-function: ease-out;
-webkit-origin:39%;
}

75% {
opacity: 1;
-webkit-transform: rotate(900deg);
-webkit-animation-timing-function: ease-out;
-webkit-origin:70%;
}

76% {
opacity: 0;
-webkit-transform:rotate(900deg);
}

100% {
opacity: 0;
-webkit-transform: rotate(900deg);
}

}

@-ms-keyframes orbit {
0% {
opacity: 1;
z-index:99;
-ms-transform: rotate(180deg);
-ms-animation-timing-function: ease-out;
}

7% {
opacity: 1;
-ms-transform: rotate(300deg);
-ms-animation-timing-function: linear;
-ms-origin:0%;
}

30% {
opacity: 1;
-ms-transform:rotate(410deg);
-ms-animation-timing-function: ease-in-out;
-ms-origin:7%;
}

39% {
opacity: 1;
-ms-transform: rotate(645deg);
-ms-animation-timing-function: linear;
-ms-origin:30%;
}

70% {
opacity: 1;
-ms-transform: rotate(770deg);
-ms-animation-timing-function: ease-out;
-ms-origin:39%;
}

75% {
opacity: 1;
-ms-transform: rotate(900deg);
-ms-animation-timing-function: ease-out;
-ms-origin:70%;
}

76% {
opacity: 0;
-ms-transform:rotate(900deg);
}

100% {
opacity: 0;
-ms-transform: rotate(900deg);
}

}

@-o-keyframes orbit {
0% {
opacity: 1;
z-index:99;
-o-transform: rotate(180deg);
-o-animation-timing-function: ease-out;
}

7% {
opacity: 1;
-o-transform: rotate(300deg);
-o-animation-timing-function: linear;
-o-origin:0%;
}

30% {
opacity: 1;
-o-transform:rotate(410deg);
-o-animation-timing-function: ease-in-out;
-o-origin:7%;
}

39% {
opacity: 1;
-o-transform: rotate(645deg);
-o-animation-timing-function: linear;
-o-origin:30%;
}

70% {
opacity: 1;
-o-transform: rotate(770deg);
-o-animation-timing-function: ease-out;
-o-origin:39%;
}

75% {
opacity: 1;
-o-transform: rotate(900deg);
-o-animation-timing-function: ease-out;
-o-origin:70%;
}

76% {
opacity: 0;
-o-transform:rotate(900deg);
}

100% {
opacity: 0;
-o-transform: rotate(900deg);
}

}

@keyframes orbit {
0% {
opacity: 1;
z-index:99;
transform: rotate(180deg);
animation-timing-function: ease-out;
}

7% {
opacity: 1;
transform: rotate(300deg);
animation-timing-function: linear;
origin:0%;
}

30% {
opacity: 1;
transform:rotate(410deg);
animation-timing-function: ease-in-out;
origin:7%;
}

39% {
opacity: 1;
transform: rotate(645deg);
animation-timing-function: linear;
origin:30%;
}

70% {
opacity: 1;
transform: rotate(770deg);
animation-timing-function: ease-out;
origin:39%;
}

75% {
opacity: 1;
transform: rotate(900deg);
animation-timing-function: ease-out;
origin:70%;
}

76% {
opacity: 0;
transform:rotate(900deg);
}

100% {
opacity: 0;
transform: rotate(900deg);
}

}
