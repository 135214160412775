//$phone-low-height: "screen (max-height: 400px)"; 
@use "sass:math";

$phone-res: "screen and (min-width: 0px) and (max-width: 470px)";
$tablet-res: "screen and (min-width: 471px) and (max-width: 768px)";
$desktop-res: "screen and (min-width: 769px) and (max-width: 1024px)";
$television-res: "screen and (min-width: 1025px)";

$red-magic-number: 241;
$green-magic-number: 691;
$blue-magic-number: 68;
$brightness-divisor: $red-magic-number+$green-magic-number+$blue-magic-number;

@function sqrt($r) {
  $x0: 1;
  $x1: $x0;

  @for $i from 1 through 10 {
    $x1: $x0 - math.div($x0 * $x0 - abs($r), 2 * $x0);
    $x0: $x1;
  }

  @return $x1;
}

@function brightness($color) {
  // Extract color components
  $red-component: red($color);
  $green-component: green($color);
  $blue-component: blue($color);

  // Calculate a brightness value in 3d color space between 0 and 255
  $number: sqrt(math.div(($red-component * $red-component * $red-magic-number) + ($green-component * $green-component * $green-magic-number) + ($blue-component * $blue-component * $blue-magic-number), $brightness-divisor));

  // Convert to percentage and return
  @return math.div(100% * $number, 255);
}

@function set-text-color($color) {
  @if (brightness($color) > 75) {
    @return darken($color, 80%); // Lighter backgorund, return dark color
  }

  @else {
    @return lighten($color, 80%); // Darker background, return light color
  }
}

@function set-panel-color($color) {
  @if (brightness($color) > 50) {
    @return darken($color, 15%); // Lighter backgorund, return dark color
  }

  @else {
    @return lighten($color, 15%); // Darker background, return light color
  }
}

//$app-background:rgb(113,177,209);
$app-background:lighten(rgb(65, 65, 75), 68%);

//strong color enphasis
$accent-background:rgb(106, 196, 234);
$accent-foreground:set-text-color($accent-background);

//editable, form-like content
$content-background:white;
$content-required:#9c0000;
$content-foreground:set-text-color($content-background);
$content-border:lighten($content-foreground, 60%);
$content-title:darken($content-foreground, 20%);
$content-label:lighten($content-foreground, 20%);
$content-panel-background:rgba(set-panel-color($app-background), 0.4);

//fixed elements like headers
$content-fixture-background:rgb(240, 240, 240);
$content-fixture-foreground:set-text-color($content-fixture-background);

//edge items, like button and navigation bars
$offscreen-background:rgb(60, 69, 79);
$offscreen-foreground:set-text-color($offscreen-background);

//edge items, like button and navigation bars
$interactive-background:rgb(60, 69, 79);
$interactive-foreground:set-text-color($offscreen-background);

$chart-item-background:#A0E3FF;
$chart-item-foreground:rgb(60, 69, 79);
$chart-item-text:rgb(60, 69, 79);

@mixin boxshadow($depth:1px) {
  box-shadow: 0 $depth ($depth*3) rgba(0, 0, 0, .12), 0 $depth ($depth*2) rgba(0, 0, 0, .24)
}

@mixin boxshadow-bottom($depth:1px) {
  box-shadow: 1px 1px 3px -$depth rgba(0, 0, 0, 0.24);
}

.acrylic {
  background: transparent;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    display: flex;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    filter: blur(25px);
    z-index: -1;
    background: $offscreen-background;
  }
}