.notifications {
  position: fixed;
  z-index: 9999;
}

/* Positioning */ 
.notifications.top-right {
  right: 10px;
  top: 25px;
}

.notifications.top-left {
  left: 10px;
  top: 25px;
}

.notifications.bottom-left {
  left: 10px;
  bottom: 25px;
}

.notifications.bottom-right {
  right: 10px;
  bottom: 25px;
}

/* Notification Element */
.notifications > div {
  position: relative;
  z-index: 9999;
  margin: 5px 0px;
}