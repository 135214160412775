@mixin lightscroll {
    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    &::-webkit-scrollbar-track {
        background-color: none;
    }

    &::-webkit-scrollbar-thumb {
        background: silver;
    }
}

//dropdowns
.dropdown {
    background: white;
    border: 1px solid silver;
    padding: 0;
    margin: 0;
    position: absolute;
    box-shadow: 0px 0px 5px 1px rgba(100, 100, 100, 0.5);
}

.dropdown.dropdown-dialog {
    z-index: 501;
    min-width: 30%;
    min-height: 50%;
    max-width: 90%;
    max-height: 90%;
    display: flex;
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.4s ease;
    border: 1px solid var(--accent-color);
    background-color: var(--accent-color);
    font: inherit;

    &.open {
        opacity: 1;
    }

    &>.dialog-window-body {
        flex: 1;
    }
}

.fullscreen-dialog {
    z-index: 501;
    display: flex;
    transition: transform 0.2s ease, opacity 0.2s ease;
    transform: translateY(50%);
    opacity: 0;
    border: none;

    &.open {
        transform: translateY(0);
        opacity: 1;
    }

    &>* {
        flex: 1;
    }
}

.dialog-window-body {
    background: white;

    &>* {
        flex: 1;
    }
}

.dialog-window-title {
    font-size: 120%;
    padding-left: 10px;
    padding-right: 5px;
    background-color: var(--accent-color);
    color: white;
    font-weight: 400;
    flex-shrink: 0;
    min-height: 30px;

    &.dialog-window-title_light {
        color: var(--accent-color);
        background-color: white;
        border-bottom: 1px solid var(--accent-color);
        font-size: 130%;
    }

    button {
        cursor: pointer;
        padding: 5px;
        margin-left: auto;
        border: none;
        background: none;
        font: inherit;
        color: inherit;
    }
}

.dialog-window_command-bar {
    min-height: 30px;
    background-color: var(--control-border);
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    padding: 1px 3px;
    justify-content: flex-end;

    &.dialog-window_command-bar_clear {
        background-color: white;
        padding: 10px;
        justify-content: center;
    }

    .dialog-window_command {
        cursor: pointer;
        border: 1px solid rgba(0, 0, 0, 0.2);
        padding: 4px 8px;
        margin: 5px 3px;
        font: inherit;
        font-size: 110%;

        &:hover {
            background-color: var(--accent-color);
            color: white;
            border-color: white;
        }
    }
}

.menu-dropdown {
    @include lightscroll;
    background: white;
    display: flex;
    flex-direction: column;
    padding: 5px;
    color: rgb(75, 75, 75);
    border-radius: 3px;

    mln-radio {
        margin: 5px 0;
        cursor: pointer;
    }

    mln-checkbox {
        margin: 5px 0;
        cursor: pointer;
    }

    .toolbar-separator {
        height: 1px;
        background: rgba(75, 75, 75, 0.3);
        margin: 5px 5px;
        padding: 0;
    }

    .toolbar-radio-group {
        margin: 5px 0;
    }

    .toolbar-text {
        border: none;
        text-align: left;
        padding: 5px;
        background: none;
        cursor: pointer;
        border-radius: 3px;

        * {
            pointer-events: none;
        }

        &:hover {
            background: var(--accent-color);
            color: white;
        }
    }
}