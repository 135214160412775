@import "./../css/flexbox";
@import "./../css/colors";

.mln-mainbody {
    @include flexbox;
    @include flex-direction(column);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    overflow: hidden;
    overflow: clip;
}

.mln-main-frame {
    display: none;
    @include flex-direction(column);
    @include flex(1);
    width: 100%;
    height: 100%;
    min-height: 0;
    min-width: 0;

    &.visible {
        //@include flexbox;
        display: inline-block;
    }
}

.mln-content-frame {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: 100%;
    flex: 1;
    opacity: 0;
    visibility: hidden;

    &.visible {
        visibility: visible;

        &.activated {
            opacity: 1;
            //transition: opacity 0.2s ease-out;
        }
    }
}

.mln-module-menu {
    display: flex;
    flex-direction: column;
    padding: 5px;
    flex-shrink: 0;

    @media only screen and (min-width: 767px) {
        display: none;
    }
}

.notifications {
    .alert-danger {
        background: #df4e4e;
        color: white;
    }

    .alert-success {
        background: #48955a;
        color: white;
    }

    .alert-warning {
        background: #c08d00;
        color: white;
    }

    .close {
        font-size: 150%;
    }

    .alert {
        @include flexbox;
        @include flex-direction(row-reverse);
        @include align-items(center);
        padding: 10px;
        border-radius: 3px;

        a {
            margin-left: 20px;
        }

        opacity: 0.95;
        transition: transform 0.3s ease-out;
        transform: translateX(110%);
        will-change: transform;

        &.visible {
            transform: translateX(-1%);
        }
    }
}

.sidebar {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    padding-right: 0;
    min-width: 300px;
    bottom: 0;
    overflow: hidden;
    position: fixed;
    top: 0;
    background-color: var(--command-color);
    z-index: 2000;
    transform: translateX(-102%);
    transition: transform 0.2s ease-out;
    display: none;

    &_header {
        border-bottom: 1px solid rgba(255, 255, 255, 0.4);
        height: 30px;
        min-height: 2.5rem;
    }

    .search-box {
        padding: 4px;
        flex: 1;
        margin: 5px;
        font: inherit;
        color: var(--control-border);
        background: transparent;
        border: none;
        &::placeholder,
        &::-webkit-input-placeholder {
            color: var(--control-border);
        }
        &::-webkit-search-cancel-button {
            -webkit-appearance: none;
            height: 10px;
            width: 10px;
            cursor: pointer;
            background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><g><path fill='red' stroke='white' stroke-width='3' stroke-linecap='round' d='M0 0 L10 10 M10 0 L0 10' /></g></svg>");
        }
    }

    #sidebar-close {
        background: transparent;
        border: none;
        color: #fff;
        cursor: pointer;
        font-weight: 400;

        &:hover {
            background: rgba(106, 196, 234, 0.3);
        }

        i {
            padding: 5px;
        }
    }

    .version {
        color: silver;
        font-size: 80%;
        margin-top: auto;
        margin-bottom: 10px;
        margin-left: 10px;
    }

    @media #{$phone-res},
    #{$tablet-res} {
        width: 80%;
    }
}

.navbar-btn {
    color: white;
    padding: 7px;
    margin: 0px;
    margin-right: 1px;
    background-color: transparent;
    border: none;
}

.nav-btn-close {
    padding: 8px 10px;
    float: left;
    font-size: 100%;
    opacity: 0.7;
    cursor: pointer;
}

.nav-btn-close:hover {
    opacity: 1;
}

.nav-btn-close:active {
    color: silver;
}

/*default module button*/
.mln-app-tab {
    margin: 6px 6px 3px 6px;
    padding: 0;

    & > li {
        color: white;
        @include flexbox();
        @include flex-direction(row);
        @include align-items(center);

        & > a {
            transform: translateX(1em);
            margin-left: 10px;
            text-decoration: none;
            color: #cccccc;
        }

        & > .nav-btn-close {
            opacity: 0;
            pointer-events: auto;
        }
    }

    /*inactive module button hover*/
    & > li:hover:not(.active),
    & > li:focus:not(.active) {
        background-color: rgb(var(--accent-color-rgb) / 0.25);
        color: white;
        opacity: 0.8;
    }

    & > .active,
    & > .active > li:hover,
    & > .active > li:focus {
        background-color: rgb(var(--accent-color-rgb) / 0.25);
        color: white;
        opacity: 1;
        border-bottom: 3px solid rgb(var(--accent-color-rgb));
        margin-bottom: -1px;

        & > .nav-btn-close {
            opacity: 1;
            pointer-events: default;
        }

        & > a {
            transform: translateX(0);
            color: inherit;
        }
    }
}

.mln-module-header {
    min-height: 2.5em;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    margin-bottom: 0;
    background-color: var(--command-color);
    color: var(--command-foreground);
    // z-index: 0;
    padding: 5px;
    height: 30px;
    opacity: 0;
    transition: opacity 0.4s ease;

    @media only screen and (max-width: 767px) {
        background-color: var(--accent-color);
        color: var(--accent-foreground);

        .mln-app-tab {
            display: none;
        }
    }
}

.notification-button {
    background: none;
    border: none;
    color: inherit;
}

.user-options {
    display: none;
    flex-direction: column;
    background-color: var(--command-foreground);
    border-radius: 3px;
    font-size: 12px;
    position: absolute;
    top: 48px;
    right: 8px;
    height: 80px;
    width: 120px;
    z-index: 1;

    &::before {
        content: "";
        width: 16px;
        height: 16px;
        background-color: var(--command-foreground);
        position: fixed;
        transform: rotate(45deg);
        top: 44px;
        right: 16px;
        z-index: -1;
    }

    &--option {
        color: var(--command-color);
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        font-weight: 500;
        overflow: hidden;
        white-space: nowrap;

        &:hover {
            background-color: darken(#ffffff, 10);
            border-radius: 3px;
        }
    }

    #logout {
        color: var(--required-field);
        cursor: pointer;
    }
}

.navbar-brand {
    margin: -5px;
    padding: 5px;
    border: none;
    border-right: 1px solid rgba(180, 64, 252);
    border-radius: 0;
    background: rgba(180, 64, 252, 0.2);
    height: auto;
    cursor: pointer;
    &:hover {
        background: rgba(180, 64, 252, 0.7);
    }

    @media #{$desktop-res},
    #{$television-res} {
        &:hover {
            color: $accent-background;
        }
    }

    .navbar-brand-logo {
        fill: white;

        @media #{$desktop-res},
        #{$television-res} {
            &:hover,
            &:active {
                fill: $accent-background;
            }
        }
    }
}

#login-div .btn {
    padding: 7.5px;
    min-width: 60px;
    border: 1px solid white;
}

.page-container {
    display: none;
    overflow: hidden;
    flex-direction: column;
    &.visible {
        display: flex;
    }
}
